import styled from '@emotion/styled';
import { Color } from '@pafcloud/style';
import { backgroundStyle } from './sharedFullWidthBackground';

export const Background = styled.div(backgroundStyle, {
  img: {
    objectPosition: '75% 50%',
  },

  '&::after': {
    content: '""',
    display: 'block',
    background: `
        linear-gradient(0deg, ${Color.Background}, transparent 66%, transparent),
        linear-gradient(0deg, ${Color.Background}59, transparent 33%, transparent)`,
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});
